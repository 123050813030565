<template>
    <div class="page" v-loading="loading">
        <div class="page-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/examinationRecord' }">考试记录</el-breadcrumb-item>
                <el-breadcrumb-item>试卷解析</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-container>
            <el-aside>
                <asideLeft :errorArr="errorArr" :parperArr="parperArr" :allNumberArr="allNumberArr"
                           :allQuestionArr="allQuestionArr" @changeMaodian="changeMaodian"></asideLeft>
            </el-aside>
            <el-main>
                <mainDetails :questionAndAnswer="questionAndAnswer" :paperTitle="paperTitle"
                             :maodian="maodian"></mainDetails>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import asideLeft from "./components/asideLeft.vue"
    import headerDetails from "./components/headerDetails.vue"
    import mainDetails from "./components/mainDetails.vue"

    import {
        examinationRecordDetails
    } from "@/api/index.js"

    export default {
        components: {
            asideLeft,
            headerDetails,
            mainDetails,
        },
        data() {
            return {
                loading: false,
                answerArr: null,
                parperArr: {},
                questionAndAnswer: [],
                allNumberArr: [],
                errorArr: [],
                allQuestionArr: [],
                paperTitle: '',
                maodian: '#1'
            }
        },
        methods: {
            changeMaodian(idName) {
                this.maodian = idName
            },
            getInfo(id) {
                this.loading = true;
                let param = {
                    id: id
                }
                examinationRecordDetails(param).then(res => {
                    if (res.code == "0000") {
                        this.allQuestionArr = res.data.paper.titleItems;
                        this.parperArr = res.data.paper;
                        this.answerArr = res.data.answerArr;
                        this.errorArr = res.data.answer.errorItems;
                        let numberArr = [];
                        res.data.paper.titleItems.forEach(item => {
                            item.questionItems.forEach(item1 => {
                                numberArr.push(item1.itemOrder)
                            })
                        })
                        this.allNumberArr = numberArr
                        let allData = {};
                        let oneItem = [];
                        allData.name = res.data.paper.name;
                        this.paperTitle = res.data.paper.name
                        allData.id = res.data.paper.id;
                        allData.level = res.data.paper.level;
                        allData.paperType = res.data.paper.paperType;
                        allData.score = res.data.paper.score;
                        allData.suggestTime = res.data.paper.suggestTime;
                        allData.type = res.data.paper.type;
                        allData.doTime = res.data.answer.doTime;
                        allData.allRecord = res.data.paper.titleItems;
                        res.data.paper.titleItems.forEach((item, index) => {
                            item.questionItems.forEach((item1, index1) => {
                                res.data.answer.answerItems.forEach((item2, index2) => {
                                    if (item1.id == item2.questionId) {
                                        allData.allRecord[index].questionItems[index1].answerInfo = item2
                                    }
                                })
                            })
                        })
                        this.questionAndAnswer = allData.allRecord;
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$router.push({
                            path: '/examinationRecord'
                        })
                    }
                }).catch(() => {
                    this.$router.push({
                        path: '/examinationRecord'
                    })
                });
            }
        },
        mounted() {
            this.getInfo(this.$route.query.id)
        }
    }
</script>

<style lang="scss" scoped>
    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }

    .page,
    .el-container {
        background-color: #EFEFEF;
    }

    .page-breadcrumb {
        padding: 15px 0;
        cursor: pointer;
    }

    .el-aside {
        color: #333;
        width: 300px;
        height: 70vh;
        background-color: #FFFFFF !important;
        /*background-color: rgb(238, 241, 246);*/
        padding-bottom: 10px;
        border-radius: 6px;
        /*overflow-x: hidden*/
        overflow: hidden;
        scrollbar-width: none; /* 针对FireFox */
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
        position: fixed;
        top: 20;
        left: 300;
    }

    .el-main {
        margin-left: 315px;
        background-color: #FFFFFF !important;
        width: 945px;
        height: 100%;
        overflow: visible;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
    }
</style>
