<template>

    <div class="paper-left">
        <p class="paper-name">{{ parperArr.name }}</p>
        <div class="paper-des">
            <p style="font-size: 12px;">总分:{{ parperArr.score }}</p>
            <p style="font-size: 12px;">时长:{{ parperArr.suggestTime }}分钟</p>
        </div>
        <el-divider></el-divider>


        <div v-for="(item,index) in allQuestionArr" :key="index">
            <div style="font-size: 12px;margin-bottom: 10px;margin-top: 10px;color: #34495e">
                {{item.name}}
            </div>
            <div>
                <a @click="clickanchor(`#q${item2}`)" v-for="item2 in item.questionOrder" :key="item2">
                    <el-button size="mini" type="infor" style="margin-left: 4.5px;margin-top: 4.5px;"
                               class="subjetButton2">
                        {{item2}}
                    </el-button>
                </a>
            </div>
        </div>
        <div class="headerSty">
            <el-button size="mini" type="primary" @click="back()">退出</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            parperArr: {
                type: Object,
                default: () => ({})
            },
            allNumberArr: {
                type: Array,
                default: () => []
            },
            errorArr: {
                type: Array,
                default: () => []
            },
            allQuestionArr: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            errorArr: {
                handler: function (n, o) {
                    let idArr = [];
                    this.errorArr.forEach(item => {
                        idArr.push(item)
                    })
                    this.$nextTick(() => {
                        // let buttonArr2 = document.getElementsByClassName("subjetButton2");
                        const elements = this.$el.getElementsByClassName('subjetButton2');
                        const elementsArray = Array.from(elements);
                        elementsArray.forEach(item => {
                            let innerHTMLValue = item.getElementsByTagName("span")[0].innerHTML;
                            if (idArr.indexOf(parseInt(innerHTMLValue)) != -1) {
                                item.classList.remove("el-button--infor"); // 设置
                                item.classList.remove("el-button--success");
                                item.classList.add("el-button--danger"); // 设置
                            } else {
                                item.classList.remove("el-button--danger"); // 设置
                                item.classList.remove("el-button--success");
                                item.classList.add("el-button--infor"); // 设置
                            }
                        })
                    })
                },
                deep: true
            },
        },
        methods: {
            clickanchor(idName) {
                this.$emit("changeMaodian", idName);
                // document.querySelector(idName).scrollIntoView({
                // 	behavior: "smooth"
                // });
            },
            back() {
                // this.$router.go(-1)
                window.close()
            },
        },

        data() {
            return {
            }
        }
    }
</script>

<style lang="scss" scoped>
     ::v-deep .el-divider--horizontal {
        // margin: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        // padding-bottom: 10px;
    }

    .paper-left {
        width: 280px;
        color: #000;
        /*padding-left: 10px;*/
        padding: 20px 10px 0 10px;
        /*overflow-y: scroll;*/


        .paper-name {
            width: 270px;
            font-size: 16px;
            color: #222;
            font-weight: 600;
            word-wrap: break-word; /* 旧版本浏览器兼容性 */
            overflow-wrap: break-word; /* 标准属性 */
            padding: 0 10px;
        }

        .paper-des {
            width: 255px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            padding-left: 10px;
        }

    }

    .subjetButton2 {
        margin-left: 6px;
        margin-top: 6px;
        width: 48px;
        height: 36px;
        border-radius: 4px;
        font-size: 14px;

        /*&:hover {*/
        /*    cursor: pointer;*/
        /*}*/
    }

    .headerSty {
        margin-top: 30px;
        width: 100%;
        text-align: center;
    }
</style>
